import { FC } from 'react';
import styled from 'styled-components';
import {
  color,
  layout,
  flexbox,
  space,
  border,
  typography,
  position,
  shadow,
  SpaceProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  LayoutProps,
  BordersProps,
  BackgroundProps,
  PositionProps,
  ShadowProps,
} from 'styled-system';

type TagProps = {
  as:
    | 'div'
    | 'img'
    | 'span'
    | 'p'
    | 'table'
    | 'td'
    | 'th'
    | 'tr'
    | 'aside'
    | 'section'
    | 'header';
  src: any;
};

export type StyledSystemProps =
  | SpaceProps
  | ColorProps
  | TypographyProps
  | FlexboxProps
  | LayoutProps
  | BordersProps
  | BackgroundProps
  | PositionProps
  | (ShadowProps & { className?: string })
  | TagProps;

export const Box: FC<StyledSystemProps> = styled.div`
  ${layout}
  ${flexbox}
  ${color}
  ${space}
  ${border}
  ${typography}
  ${position}
  ${shadow}
  box-sizing: border-box;
`;

import React from 'react';
import { Box } from '../Box';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { STIcon } from 'design/atoms/Icon';

const ButtonStyled = styled(Box)(
  {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  variant({
    variants: {
      primary: {
        color: 'white',
        bg: 'primary',
        borderColor: 'primary',
        '&:hover': {
          backgroundColor: 'primaryHover',
        },
      },
      secondary: {
        color: 'primary',
        bg: 'white',
        borderColor: 'primary',
        '&:hover': {
          backgroundColor: 'secondaryHover',
        },
      },
      error: {
        color: 'error',
        bg: 'white',
        borderColor: 'error',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
      disabled: {
        color: 'white',
        bg: 'disabled',
        borderColor: 'disabled',
        '&:hover': {
          backgroundColor: 'disabled',
        },
      },
    },
  })
);

export const STButton = ({ children, ...props }) => (
  <ButtonStyled
    as="button"
    variant="primary"
    p={2}
    width="100%"
    height="40px"
    fontSize={2}
    fontWeight="bold"
    borderRadius={1}
    borderStyle="solid"
    borderWidth="1px"
    textAlign="center"
    {...props}
  >
    {children}
  </ButtonStyled>
);

const IconContainerStyled = styled.div`
  float: ${({ float }) => float};
  margin-top: -2px;
`;

export const STButtonWithIcon = ({
  leftIcon,
  rightIcon,
  children,
  theme,
  ...props
}) => (
  <STButton {...props}>
    {leftIcon ? (
      <IconContainerStyled float="left">{leftIcon}</IconContainerStyled>
    ) : null}
    {children}
    {rightIcon ? (
      <IconContainerStyled float="right">{rightIcon}</IconContainerStyled>
    ) : null}
  </STButton>
);

// TODO this can be in its own file
const IconButtonStyled = styled(Box)(
  {
    cursor: 'pointer',
  },
  variant({
    variants: {
      primary: {
        color: 'primary',
        bg: 'white',
        borderColor: 'grey20',
        '&:hover': {
          backgroundColor: 'secondaryHover',
          borderColor: 'primary',
        },
      },
      secondary: {
        color: 'white',
        bg: 'primary',
        borderColor: 'primary',
        '&:hover': {
          bg: 'primaryHover',
          borderColor: 'primaryHover',
        },
      },
      error: {
        color: 'error',
        bg: 'white',
        borderColor: 'grey20',
        '&:hover': {
          color: 'errorHover',
          borderColor: 'errorHover',
        },
      },
    },
  })
);

export const STIconButton = ({ icon, ...props }) => (
  <Box p="7px" display="inline-block">
    <IconButtonStyled
      data-cy="st-icon-button"
      variant="primary"
      borderStyle="solid"
      borderWidth="1px"
      p={1}
      width="32px"
      height="32px"
      borderRadius={1}
      {...props}
    >
      <STIcon icon={icon} size="sm" smSize="xs" />
    </IconButtonStyled>
  </Box>
);

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  html {
    font-size: ${props => props.theme.fontSizes.body}px;
  }
  body {
    box-sizing: border-box;
    font-weight: ${props => props.theme.weights.regular};
    overflow-y : auto;
  }
  body, #root {
    min-height: 100vh;
    background-color: ${props => props.theme.colors.background}
  }

  .date-picker-wrapper {
    width: 100%;
  }
`;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import defaultTheme from 'design/theme';
import { STToastContainer } from 'design/atoms/Toast';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={defaultTheme}>
        <STToastContainer />
        {children}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AppProviders;

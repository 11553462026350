import React from 'react';
import styled from 'styled-components';
import { color, typography, space, layout, variant } from 'styled-system';

const TextSystem = styled.div`
  ${color}
  ${typography}
  ${space}
  ${layout}
`;

const TextStyled = styled(TextSystem)(
  {},
  variant({
    variants: {
      blackBold: {
        fontWeight: 700,
        color: 'black',
      },
      blackMedium: {
        fontWeight: 500,
        color: 'black',
      },
      blackRegular: {
        fontWeight: 400,
        color: 'black',
      },
      blackRegularClickable: {
        fontWeight: 400,
        color: 'black',
        cursor: 'pointer',
      },
      lightGreyRegular: {
        color: 'grey40',
        fontWeight: 400,
      },
      lightGreyMedium: {
        color: 'grey40',
        fontWeight: 500,
      },
      lightGreyBold: {
        color: 'grey40',
        fontWeight: 700,
        cursor: 'pointer',
      },
      greyRegular: {
        color: 'grey70',
        fontWeight: 400,
      },
      greyMedium: {
        color: 'grey70',
        fontWeight: 500,
      },
      greyBold: {
        color: 'grey70',
        fontWeight: 700,
      },
      blueRegular: {
        color: 'primary',
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      blueBold: {
        color: 'primary',
        fontWeight: 700,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      blueBoldClean: {
        color: 'primary',
        fontWeight: 700,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      whiteBold: {
        color: 'white',
        fontWeight: 700,
      },
      redRegular: {
        color: 'error',
        fontWeight: 400,
      },
      orangeBold: {
        fontWeight: 700,
        color: 'orange',
      },
    },
  })
);

export const BaseText = ({ children, ...props }) => (
  <TextStyled fontFamily="primary" {...props}>
    {children}
  </TextStyled>
);

export const STH1 = ({ children, ...props }) => (
  <BaseText as="h1" variant="blackRegular" fontSize={4} {...props}>
    {children}
  </BaseText>
);

export const STH2 = ({ children, ...props }) => (
  <BaseText as="h2" variant="blackRegular" fontSize={3} {...props}>
    {children}
  </BaseText>
);

export const STH3 = ({ children, ...props }) => (
  <BaseText as="h3" variant="blackRegular" fontSize={2} {...props}>
    {children}
  </BaseText>
);

export const STBodyText = ({ children, ...props }) => (
  <BaseText as="div" variant="blackRegular" fontSize={1} {...props}>
    {children}
  </BaseText>
);

export const STClickableText = ({ children, ...props }) => (
  <BaseText as="div" variant="blackRegularClickable" fontSize={1} {...props}>
    {children}
  </BaseText>
);

export const STDisabledBodyText = ({ children, ...props }) => (
  <STBodyText as="div" variant="lightGreyRegular" {...props}>
    {children}
  </STBodyText>
);

export const STLabel = ({ children, ...props }) => (
  <STBodyText as="label" variant="greyRegular" {...props}>
    {children}
  </STBodyText>
);

export const STLink = ({ children, ...props }) => (
  <STBodyText as="span" variant="blueBold" {...props}>
    {children}
  </STBodyText>
);

export const STActiveLink = ({ children, ...props }) => (
  <STBodyText as="span" variant="blueBoldClean" {...props}>
    {children}
  </STBodyText>
);

export const STSecondaryLink = ({ children, ...props }) => (
  <BaseText as="span" variant="lightGreyBold" fontSize={3} {...props}>
    {children}
  </BaseText>
);

export const STErrorText = ({ children, ...props }) => (
  <STBodyText as="span" variant="redRegular" {...props}>
    {children}
  </STBodyText>
);

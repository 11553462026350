import React from 'react';
import { Field } from 'formik';
import { STLabel, STErrorText } from 'design/atoms/Text';
import { STInput } from 'design/atoms/Input';
import { Box } from 'design/atoms/Box';

export const STInputField = React.forwardRef(
  ({ label, error, ...props }, ref) => (
    <Box mb={3}>
      <STLabel variant="greyBold">{label}</STLabel>
      <STInput mb={1} mt={1} error={error} ref={ref} {...props}></STInput>
      <STErrorText>{error}&nbsp;</STErrorText>
    </Box>
  )
);

export const FormikInputField = ({ name, type, validate, ...props }) => (
  <Field name={name} type={type} validate={validate}>
    {({ field, form }) => (
      <STInputField
        name={name}
        type={type}
        value={form.values[name]}
        error={form.touched[name] ? form.errors[name] : null}
        {...props}
        {...field}
      />
    )}
  </Field>
);

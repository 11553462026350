import React, { useState } from 'react';
import { STInput } from 'design/atoms/Input';
import { Box } from 'design/atoms/Box';
import DatePicker from 'react-datepicker';
import { STSlidingPanel } from 'design/atoms/SlidingPanel';
import { STIcon } from 'design/atoms/Icon';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

class DatePickerInput extends React.Component {
  render() {
    return (
      <STInput
        {...this.props}
        ref={this.props.inputRef}
        placeholder={this.props.placeholderText}
      />
    );
  }
}

export const STDatePicker = props => {
  return (
    <DatePicker
      formatWeekDay={d => {
        return d[0];
      }}
      customInput={
        <DatePickerInput {...props.inputProps} inputRef={props.inputRef} />
      }
      {...props}
    />
  );
};

export const STMobileDatePicker = ({ date, setDate, ...props }) => {
  const [isOpen, setOpened] = useState(true);
  const selectDate = selectedDate => {
    setDate(selectedDate);
    setOpened(false);
  };
  // TODO onClick datepicker inptu will open the keyboard. Need to blur.
  return (
    <>
      <DatePickerInput
        value={date ? date : ''}
        onClick={() => setOpened(true)}
      />
      <STSlidingPanel isOpen={isOpen}>
        <Box
          height={60}
          pt={15}
          pl={15}
          color="white"
          width="100%"
          bg="primary"
        >
          <STIcon icon="ArrowLeft" onClick={() => setOpened(false)} size="md" />
        </Box>
        <STDatePicker
          inline
          selected={date}
          startDate={date}
          onChange={selectDate}
          monthsShown={2}
          shouldCloseOnSelect={false}
          {...props}
        />
      </STSlidingPanel>
    </>
  );
};

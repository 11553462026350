import React from 'react';
import styled from 'styled-components';
import { TimePicker } from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import qs from 'qs';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import nprogress from 'nprogress';
import { format, differenceInHours } from 'date-fns';

import CoverImage from './static/images/airport-transfer-1500-banner.jpg';
import { STFlex } from 'design/atoms/Container';
import { Box } from 'design/atoms/Box';
import { STInput, STTextArea } from 'design/atoms/Input';
import { STH1, STLabel, BaseText, STErrorText } from 'design/atoms/Text';
import { STHR } from 'design/atoms/HR';
import { STButton } from 'design/atoms/Button';
import { STDatePicker } from 'design/atoms/DatePicker';
import { STInputField } from 'design/molecules/InputField';
import { emailRegex, contactRegex, sources } from './constants';
import {
  formatTime,
  isDate,
  resetURL,
  getFormattedContactNumber,
} from 'src/utils/helpers';
import { auth, db, analytics } from './firebase';
import { firestore } from 'firebase/app';
import { STErrorToast, STSuccessToast } from 'design/atoms/Toast';
import TimeWarningModal from './TimeWarningModal';

const StyledCoverImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

type Inputs = {
  fullname: string;
  contactNo: string;
  email: string;
  orderNo: string;
  flightNo: string;
  pickupDate: Date;
  pickupTime: string;
  pickupFrom: string;
  pickupAddr: string;
};

const defaultValues = {
  fullname: '',
  contactNo: '',
  email: '',
  orderNo: '',
  flightNo: '',
  pickupDate: new Date(),
  pickupTime: undefined,
  pickupFrom: 'home',
  pickupAddr: '',
};

const schema = yup.object().shape({
  fullname: yup.string().required('Full name is required'),
  contactNo: yup
    .string()
    .required('Contact no. is required')
    .matches(contactRegex, 'Contact no. format should be 0312-3456789'),
  email: yup.string().required('Email is required').email('Email is incorrect'),
  orderNo: yup.string().required('Order no. is required'),
  flightNo: yup.string(),
  pickupDate: yup.date().required('Pick up date is required'),
  pickupTime: yup.string().required('Pick up time is required'),
  pickupFrom: yup.string().required('Pick up from is required'),
  pickupAddr: yup.string().required('Pick up address is required'),
});

const getSearchParams = () => window.location.search.slice(1);

const getDefaultValuesFromURL = params => {
  const parsedParams = qs.parse(params);

  const res = {
    fullname: parsedParams.fullname || '',
    contactNo: getFormattedContactNumber(parsedParams.contactNo) || '',
    email: parsedParams.email || '',
    orderNo: parsedParams.orderNo || '',
    flightNo: parsedParams.flightNo || '',
    pickupDate: parsedParams.pickupDate
      ? new Date(parsedParams.pickupDate)
      : new Date(),
    pickupTime: parsedParams.pickupTime
      ? isDate(new Date(parsedParams.pickupTime))
        ? formatTime(new Date(parsedParams.pickupTime))
        : parsedParams.pickupTime
      : undefined,

    pickupFrom: ['home', 'airport'].includes(parsedParams.pickupFrom)
      ? parsedParams.pickupFrom
      : 'home',
    pickupAddr: parsedParams.pickupAddr || '',
  };

  return res;
};

//configuring nprogress on book transfers button
nprogress.configure({ parent: '#bookTransfer' });

const Form = () => {
  const {
    handleSubmit,
    register,
    errors,
    watch,
    control,
    setValue,
    reset,
  } = useForm<Inputs>({
    defaultValues: getDefaultValuesFromURL(getSearchParams()),
    resolver: yupResolver(schema),
  });

  const [user, setUser] = React.useState<any | null>(null);
  const [time, setTime] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTimeWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [source, setSource] = React.useState(sources.direct);
  const pickupDateValue = watch('pickupDate');
  const pickupFromValue = watch('pickupFrom');

  const onSubmit = async (data: Inputs) => {
    if (user && data) {
      analytics.logEvent('book_transfer', {
        pickUpTime: data.pickupTime,
        sastaTicketOrderNumber: data.orderNo,
        pickUpFrom: data.pickupFrom,
      });
      setIsLoading(true);
      nprogress.start();
      try {
        const pickupDateTime = `${format(
          new Date(data.pickupDate),
          'dd-MMM-yyyy'
        )} ${data.pickupTime}`;

        db.collection('airport-transfers-booking')
          .add({
            fullname: data.fullname,
            contactNo: data.contactNo,
            email: data.email,
            orderNo: data.orderNo,
            flightNo: data.flightNo,
            pickupDateTime,
            pickupFrom: data.pickupFrom,
            pickupAddr: data.pickupAddr,
            userId: user.uid,
            created_at: firestore.FieldValue.serverTimestamp(),
            source,
          })
          .then(async user => {
            const res = await fetch(
              'https://cors-anywhere.herokuapp.com/' +
                'https://formsubmit.co/ajax/ed62f3c298cdc69798f4ac43d9ac6fff',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  fullname: data.fullname,
                  email: data.email,
                  orderNo: data.orderNo,
                  flightNo: data.flightNo,
                  pickuptime: data.pickupTime,
                  pickupFrom: data.pickupFrom,
                  pickupAddr: data.pickupAddr,
                  contactNo: data.contactNo,
                  pickupDate: format(new Date(data.pickupDate), 'dd-MMM-yyyy'),
                  source,
                }),
              }
            );
            await res.json();
            reset(defaultValues);
            resetURL();
            setIsLoading(false);
            nprogress.done();

            if (getPickUpTimeDifferenceInHours(pickupDateTime) < 24) {
              setIsWarningModalOpen(true);
            } else {
              toast.success(
                <STSuccessToast message="Thank you for your request! Our team will be in touch soon." />
              );
            }
          });
      } catch (error) {
        toast.error(<STErrorToast message="Something Went Wrong!" />);
        setIsLoading(false);
        nprogress.done();
      }
    } else {
      toast.error(<STErrorToast message="Something Went Wrong!" />);
    }
  };

  const onTimeChange = time => {
    setValue('pickupTime', time);
    setTime(time);
  };

  React.useEffect(() => {
    //authenticating with anonymous user
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      }
    });

    const newSource = getSource(getSearchParams());
    if (newSource) {
      setSource(newSource);
      logSourceEvent(newSource);
    } else {
      logSourceEvent(source);
    }
    // eslint-disable-next-line
  }, []);

  const logSourceEvent = source => {
    analytics.logEvent('source', {
      source,
    });
  };

  const getSource = (params): string | null => {
    const parsedParams = qs.parse(params);
    if (parsedParams.source) {
      return parsedParams.source;
    }
    return null;
  };

  const logFormInputs = eventName => {
    analytics.logEvent(eventName);
  };

  const getPickUpTimeDifferenceInHours = dateTime => {
    return differenceInHours(new Date(dateTime), Date.now());
  };

  return (
    <STFlex
      width={['100%', '100%', '60%']}
      minHeight="97vh"
      mx="auto"
      mb={5}
      flexDirection="column"
    >
      <Box my={3}>
        <StyledCoverImage src={CoverImage} />
      </Box>
      <Box
        width="100%"
        minHeight="190px"
        maxHeight="50vw"
        maxWidth="100vw"
        bg="white"
        boxShadow="dropshadow"
        borderRadius={1}
        p={2}
        mb={3}
      >
        <STH1 variant="orangeBold" mb={1}>
          Airport Transfers
        </STH1>
        <STHR />
        <BaseText variant="greyRegular" fontSize={[0, 1]} mt={2}>
          SastaTicket is now offering airport transfer service in{' '}
          <b>Karachi!</b>. You can book your transfer from/to Jinnah
          International Airport to/from anywhere in Karachi for PKR 1,500! Just
          fill in your details and our team will get back to you.
        </BaseText>

        <BaseText variant="greyRegular" fontSize={[0, 1]} mt={2}>
          For more details please call{' '}
          <a href="tel:00921111172782">021 111 172 782</a> or Whatsapp us
          at&nbsp;
          <a href="https://wa.me/923047772782">https://wa.me/923047772782</a>
        </BaseText>
      </Box>
      <Box
        width="100%"
        maxWidth="100vw"
        bg="white"
        boxShadow="dropshadow"
        borderRadius={1}
        p={2}
      >
        <STFlex
          flexDirection="column"
          height="100%"
          mx="auto"
          mt={4}
          width={['80%', '50%']}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <STInputField
              name="fullname"
              label="Full name"
              error={errors.fullname?.message}
              ref={register({ required: true })}
              onFocus={() => logFormInputs('fullName_collect')}
            />
            <Controller
              as={props => (
                <STInputField
                  {...props}
                  label="Contact no."
                  error={errors.contactNo?.message}
                  as={InputMask}
                  mask="9999-9999999"
                  onFocus={() => logFormInputs('ContactNo_collect')}
                />
              )}
              ref={register({ required: true, pattern: contactRegex })}
              control={control}
              name="contactNo"
              defaultValue=""
            />
            <STInputField
              name="email"
              label="Email"
              error={errors.email?.message}
              ref={register({ required: true, pattern: emailRegex })}
              onFocus={() => logFormInputs('email_collect')}
            />
            <STFlex flexDirection={['column', 'row']}>
              <STFlex width={[2 / 2, 6 / 12]} mr={2}>
                <STInputField
                  name="orderNo"
                  label="SastaTicket Order no."
                  error={errors.orderNo?.message}
                  ref={register({ required: true })}
                  onFocus={() => logFormInputs('orderNo_collect')}
                />
              </STFlex>
              <STFlex width={[2 / 2, 6 / 12]}>
                <STInputField
                  name="flightNo"
                  label="Flight no."
                  error={errors.flightNo?.message}
                  ref={register()}
                  onFocus={() => logFormInputs('flightNo_collect')}
                />
              </STFlex>
            </STFlex>
            <STFlex flexDirection={['column', 'row']} mb={3}>
              <STFlex width={[2 / 2, 6 / 12]} mr={2} mb={3}>
                <div>
                  <STLabel variant="greyBold">Pick up date</STLabel>
                  <Controller
                    as={props => (
                      <STDatePicker
                        {...props}
                        wrapperClassName="date-picker-wrapper"
                        dateFormat="dd-MMM-yyyy"
                        selected={pickupDateValue}
                        placeholderText=""
                        inputProps={{
                          error: errors.pickupDate,
                          mt: 1,
                        }}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                        onFocus={() => {
                          logFormInputs('pickUpDate_collect');
                        }}
                      />
                    )}
                    name="pickupDate"
                    control={control}
                    defaultValue=""
                    shouldCloseOnSelect={true}
                    rules={{
                      required: true,
                    }}
                  />
                  <STErrorText>{errors.pickupDate?.message}</STErrorText>
                </div>
              </STFlex>
              <STFlex width={[2 / 2, 6 / 12]} mb={3}>
                <label>
                  <STLabel variant="greyBold">Pick up time</STLabel>
                  <TimePicker
                    value={time}
                    onChange={onTimeChange}
                    TextFieldComponent={props => {
                      return (
                        <STInput
                          name="pickupTime"
                          ref={register({ required: true })}
                          color="grey70"
                          {...props}
                          error={errors.pickupTime?.message}
                          mt={1}
                          onFocus={() => logFormInputs('pickUpTime_collect')}
                        />
                      );
                    }}
                  />
                  <STErrorText>{errors.pickupTime?.message}</STErrorText>
                </label>
              </STFlex>
            </STFlex>
            <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
              <STLabel variant="greyBold" mr={2} mb={3}>
                Pick up from:{' '}
              </STLabel>
              <Box
                mb={3}
                mr={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <input
                  id="home-radio"
                  name="pickupFrom"
                  ref={register({ required: true })}
                  value="home"
                  type="radio"
                  onFocus={() => logFormInputs('pickUpFrom_collect')}
                />
                <label htmlFor="home-radio">
                  <BaseText color="grey70" ml={2}>
                    Home
                  </BaseText>
                </label>
              </Box>
              <Box
                mb={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <input
                  id="airport-radio"
                  name="pickupFrom"
                  ref={register({ required: true })}
                  value="airport"
                  type="radio"
                  onFocus={() => logFormInputs('pickUpFrom_collect')}
                />
                <label htmlFor="airport-radio">
                  <BaseText color="grey70" ml={2}>
                    Airport
                  </BaseText>
                </label>
              </Box>
            </Box>
            <Box minHeight="115px" mb={3}>
              <STLabel variant="greyBold">
                {pickupFromValue === 'home'
                  ? 'Pick up address'
                  : 'Drop off address'}
              </STLabel>
              <STTextArea
                name="pickupAddr"
                ref={register({ required: true })}
                fontFamily="primary"
                rows="30"
                height={70}
                error={errors.pickupAddr}
                mt={1}
                onFocus={() => logFormInputs('pickUpAddress_collect')}
              />
              <STErrorText>{errors.pickupAddr?.message}</STErrorText>
            </Box>
            <STFlex
              justifyContent="center"
              alignItems="center"
              width="50%"
              mx="auto"
              id="bookTransfer"
            >
              <STButton
                disabled={isLoading}
                type="submit"
                mb={4}
                variant={isLoading ? 'disabled' : 'primary'}
              >
                <BaseText fontSize={[0, 0, 2]}>Book Transfer</BaseText>
              </STButton>
            </STFlex>
          </form>
        </STFlex>
      </Box>
      <TimeWarningModal
        isOpen={isTimeWarningModalOpen}
        closeModal={() => {
          setIsWarningModalOpen(false);
        }}
      />
    </STFlex>
  );
};

export default Form;

export const redirectToPage = (
  push: any,
  redirectTo: string,
  params = null
) => {
  push({ pathname: redirectTo, search: params });
};

// Put the object into storage
export const setInLocalStorage = (name: string, value: string) =>
  localStorage && localStorage.setItem(name, value);

// Remove the object into storage
export const removeFromLocalStorage = (name: string) =>
  localStorage && localStorage.removeItem(name);

// Get the object from storage
export const getFromLocalStorage = (name: string) =>
  localStorage && localStorage.getItem(name);

export const parseLocalStorageData = (key: string) => {
  const data = getFromLocalStorage(key);
  const decryptedData = data;
  return decryptedData && JSON.parse(decryptedData);
};

export const formatTime = (date: Date) => {
  return `${date.getHours() % 12 || '12'}:${date.getMinutes()} ${
    date.getHours() < 12 ? 'AM' : 'PM'
  }`;
};

export const isDate = d => {
  return d instanceof Date && !isNaN((d as unknown) as number);
};

export const resetURL = () => {
  const newURL = window.location.protocol + '//' + window.location.host + '/';
  window.history.replaceState({ path: newURL }, '', newURL);
};

export const getFormattedContactNumber = number => {
  if (number) {
    if (number.substr(0, 2) === '92') {
      return getMaskedNumber(0 + number.substr(2));
    } else if (number.substr(0, 3) === ' 92') {
      return getMaskedNumber(0 + number.substr(3));
    } else if (number.length === 11) {
      return getMaskedNumber(number);
    } else {
      return '';
    }
  } else {
    return '';
  }
};

const getMaskedNumber = number => {
  return number.substr(0, 4) + '-' + number.substr(4);
};

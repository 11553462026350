import React from 'react';
import { ModalProvider } from 'styled-react-modal';

import { STModal } from 'design/molecules/Modal/Modal';
import { STBodyText } from 'design/atoms/Text';
import { STFlex } from 'design/atoms/Container';
import { STButton } from 'design/atoms/Button';

type TimeWarningModalProps = {
  isOpen: boolean;
  closeModal: Function;
};

const TimeWarningModal: React.FC<TimeWarningModalProps> = ({
  isOpen,
  closeModal,
}) => {
  return (
    <ModalProvider>
      <STModal isOpen={isOpen}>
        <>
          <STFlex textAlign="center">
            <STBodyText mt={4} fontSize={2}>
              Pickup time is less than 24 hours. We will try our best to service
              you but under 24 hours booking are difficult for us to entertain.
              Thank you for your request our team will be in touch with you
              soon.
            </STBodyText>
          </STFlex>

          <STFlex mt={5} width="100%" justifyContent="center">
            <STButton
              width="30%"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </STButton>
          </STFlex>
        </>
      </STModal>
    </ModalProvider>
  );
};

export default TimeWarningModal;

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const contactRegex = /^[0-9 -]{12}$/;

export const sources = {
  email: 'email',
  direct: 'direct',
  web: 'sasta-web',
};
